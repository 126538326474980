import { ArrowUpOutlined, LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';
import { Button, message, Result, Row, Space, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateResume } from '../common/api';
import { isWorkClassUrl } from '../common/utils';
import DefaultLayout from '../components/layouts/default.layout';
import { IState } from '../state/types';
const { Title, Paragraph, Text } = Typography;

const CompletePage: React.FC = () => {
  const {
    summary,
    workExperience,
    education,
    qualifications,
    jobseekerHash,
    source,
    persistence,
    redirect,
    country
  } = useSelector((state: IState) => state);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [resumeUrl, setResumeUrl] = useState<string>();
  const [retries, setRetries] = useState(0);

  useEffect(() => {
    generate();
  }, []);

  const generate = async () => {
    setError(false);
    let success = false;
    let response = null;
    try {
      response = await generateResume(
        jobseekerHash,
        {
          summary,
          workExperience,
          education,
          qualifications
        },
        source,
        persistence
      );
      success = response.success;
      setResumeUrl(response.url);
    } catch (err) {
      Sentry.captureException(
        'Failure when generating resume! [in catch]',
        context => {
          context.setLevel(Severity.Fatal);
          context.setExtras({
            error: err,
            error_fields: err?.errorFields[0],
            error_name: err.name,
            error_message: err.message,
            stack_trace: err.stack
          });
          return context;
        }
      );
    } finally {
      if (success) {
        message.success({ content: 'Resume successfully generated!' });
        setDone(true);
        // if redirect is available redirect user back to uri
        if (redirect && isWorkClassUrl(redirect)) {
          message.success({
            content: "Please wait, you'll be redirected back"
          });
          setTimeout(() => {
            window.location.href = redirect;
          }, 2000);
        }
        // dispatch(clearResumeFields());
      } else {
        // If generation fails, retry 2 times else fail
        const localRetries = retries + 1;
        setRetries(localRetries);

        if (localRetries < 5) {
          Sentry.captureException('Retrying resume generation', context => {
            context.setLevel(Severity.Fatal);
            context.setExtras({
              error: response
            });
            return context;
          });

          generate();
        }

        setError(true);
        message.error({
          content: 'Failed to generate your resume, please try again'
        });

        Sentry.captureException(
          'Failure when generating resume [in finally]',
          context => {
            context.setLevel(Severity.Fatal);
            context.setExtras({
              error: response
            });
            return context;
          }
        );
      }
    }
  };

  const formatReturnString = () => {
    if (source === 'website') {
      return (
        <div>
          <p>
            We will email you your resume and set your WorkClass resume to the
            newly generated to this one
          </p>
          <a href={`https://workclass.co/${country}`}>
            You can return to WorkClass.co now
          </a>
        </div>
      );
    } else return <p>You can return to {source} now</p>;
  };

  if (done) {
    return (
      <>
        {source === 'telegram' && (
          <Row>
            <Space direction="vertical">
              <ArrowUpOutlined style={{ fontSize: '60px' }} />
              <Text>Click Here</Text>
            </Space>
          </Row>
        )}

        <Result
          status="success"
          title="Success!"
          subTitle={formatReturnString()}
          extra={[
            // <Button key={0} onClick={() => navigate('/qualifications')}>
            //   <LeftOutlined />
            // </Button>,
            source !== 'telegram' && (
              <a key={1} href={resumeUrl} download>
                <Button>Download Resume</Button>
              </a>
            )
          ]}
        />
      </>
    );
  }

  return (
    <DefaultLayout>
      <div style={s.spacer}>
        <Title level={2}>All Done!</Title>
        <Paragraph>We are generating your resume.</Paragraph>

        {!error ? (
          <Spin
            indicator={
              <LoadingOutlined style={{ ...s.spacer, fontSize: 50 }} spin />
            }
          />
        ) : (
          <Button key="2" onClick={generate}>
            Generate Resume Again
          </Button>
        )}
      </div>
    </DefaultLayout>
  );
};

export default CompletePage;

const s: Stylesheet = {
  spacer: {
    textAlign: 'center',
    marginTop: 50
  },
  btn: {
    width: 200,
    height: 50
  },
  naviBtn: {
    minHeight: 40,
    minWidth: 120
  }
};
